import {Component} from '@angular/core';
import {Config} from "datatables.net";
import {firstValueFrom, lastValueFrom, Subject} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {faTrash, faPenToSquare, faPlay, faPause,faEye} from '@fortawesome/free-solid-svg-icons';
import {PluginRepository} from "../../repositories/plugin.repository";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DataTablesModule} from "angular-datatables";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";
import {AddPluginComponent} from "../../components/add-plugin/add-plugin.component";
import {HasRoleDirective} from "../../directives/has-role.directive";
import {ViewPluginComponent} from "../../components/view-plugin/view-plugin.component";


@Component({
  selector: 'app-plugin-page',
  standalone: true,
    imports: [CommonModule, FontAwesomeModule, DataTablesModule, ToKbPipe, TranslateModule, HasRoleDirective],
  templateUrl: './plugin-page.component.html',
  styleUrl: './plugin-page.component.css'
})
export class PluginPageComponent {
  faTrash = faTrash;
  faEye = faEye;
  faPenToSquare = faPenToSquare;
  faPause = faPause;
  faPlay = faPlay;
  pluginList: any = null;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  private dtElement: any;

  constructor(private pluginRepository: PluginRepository, private modalService: NgbModal, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.pluginRepository.getPlugins().subscribe((res: any) => {
      this.pluginList = res;
      console.log(this.pluginList);
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      console.log("table event")
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async openUploadDialog(pluginId: string = ""): Promise<void> {
    let pluginDetails = await lastValueFrom(this.pluginRepository.getPlugin(pluginId));
    console.log(pluginDetails);
    const modalRef = this.modalService.open(AddPluginComponent, {size: 'lg'});
    modalRef.componentInstance.plugin = pluginDetails;
    modalRef.result.then((result) => {
      if (result) {
        console.log('Plugin uploaded successfully');
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }

  unpublishPlugin(pluginId: string) {
    this.pluginRepository.unpublishPlugin(pluginId).subscribe((res: any) => {
      this.router.navigate([this.router.url]).then(() => {
          window.location.reload();
      });
    });
  }

  publishPlugin(pluginId: string) {
    this.pluginRepository.publishPlugin(pluginId).subscribe((res: any) => {
      this.router.navigate([this.router.url]).then(() => {
        window.location.reload();
      });
    });
  }

  save() {
    let plugin = new Plugin();
    this.pluginRepository.save(plugin);
  }

  async deleteItem(pluginId: string) {
    await lastValueFrom(this.pluginRepository.deleteItem(pluginId));
  }

  showSwal(docId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.deleteItem(docId)
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your plugin has been deleted.",
          icon: "success"
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          }
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your plugin is safe :)",
          icon: "error"
        });
      }
    });
  }

  async openView(pluginId: any) {
    let pluginDetails = await lastValueFrom(this.pluginRepository.getPlugin(pluginId));
    const modalRef = this.modalService.open(ViewPluginComponent, {size: 'lg'});
    modalRef.componentInstance.plugin = pluginDetails;
  }
}
