import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {JsonPipe, NgIf} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MarkdownComponent} from "ngx-markdown";
import {DocumentRepository} from "../../../repositories/document.repository";
import {PartitionViewComponent} from "../../partitionComponents/partition-view/partition-view.component";
declare var bootstrap: any;
@Component({
  selector: 'app-answer-generation',
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    FormsModule,
    JsonPipe,
    NgIf,
    MarkdownComponent
  ],
  templateUrl: './answer-generation.component.html',
  styleUrl: './answer-generation.component.css'
})
export class AnswerGenerationComponent implements OnInit{
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  protected readonly STEP_STATUS = STEP_STATUS;
  processedText: SafeHtml | undefined;
  toolingId: string | null = "";
  tooltipData: SafeHtml = "";
  constructor(private documentService: DocumentRepository,private modalService: NgbModal, private ragRepository: RagRepository, private route: ActivatedRoute, private sanitizer: DomSanitizer) {

  }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });
   this.processedText = this.replaceGuids(this.step.action?.rawData);
  }


  replaceGuids(text: string): SafeHtml {
    const guidRegex = /\[([0-9a-fA-F-]{36})\]/g;
    const processedText = text.replace(guidRegex, (match, guid) => {
      return `<span class="clickable-guid guid-${guid}"  style="cursor: pointer; color: blue; text-decoration: underline;">[${guid}]</span>`;
    });

    console.log(processedText);
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
  onGuidClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('clickable-guid')) {
      const classList = Array.from(target.classList);
      const guidClass = classList.find((cls) => cls.startsWith('guid-'));
      if (guidClass) {
        const guid = guidClass.slice(5); // Remove the 'guid-' prefix
        this.handleClick(guid);
      } else {
        console.error('GUID class not found');
      }
    }
  }

  async handleClick(guid: string): Promise<void> {
    console.log('GUID clicked:', guid);
    const matchingReferece: any = this.getDataForGuid(guid);
    const tooltipDataRaw = this.documentService.getDocumentPartitionDetails(matchingReferece.documentId, matchingReferece.partitionId).subscribe(partition => {
      this.documentService.getDocument(matchingReferece.documentId).subscribe(document => {
      this.openDialog(partition,document);});
    });

  }

  openDialog(toolTipData:any,document: any) {

    const modalRef = this.modalService.open(PartitionViewComponent, {size: 'lg'});
    modalRef.componentInstance.partition= toolTipData;
    modalRef.componentInstance.document= document;
    modalRef.result.then((result) => {
      if (result) {
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }
  getDataForGuid(guid: string): string {
    if (this.step?.action?.references) {    console.log(this.step?.action?.references);
      const matchingReference = this.step.action.references.find((ref: any) => ref.partitionId === guid);
      if (matchingReference) {
        return matchingReference;
      }
    }
    return 'No matching reference found for GUID';
  }

  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }
}
